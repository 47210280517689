import request from '@/utils/request'


// 查询用户登录记录列表
export function listUser(query) {
  return request({
    url: '/biz/userLogin/list',
    method: 'get',
    params: query
  })
}

// 查询用户登录记录分页
export function pageUser(query) {
  return request({
    url: '/biz/userLogin/page',
    method: 'get',
    params: query
  })
}
// 查询用户登录记录分页
export function pageLoginUser(query) {
  return request({
    url: '/biz/userLogin/getUserLoginPage',
    method: 'get',
    params: query
  })
}

// 查询用户登录记录详细
export function getUser(data) {
  return request({
    url: '/biz/userLogin/detail',
    method: 'get',
    params: data
  })
}

// 新增用户登录记录
export function addUser(data) {
  return request({
    url: '/biz/userLogin/add',
    method: 'post',
    data: data
  })
}

// 修改用户登录记录
export function updateUser(data) {
  return request({
    url: '/biz/userLogin/edit',
    method: 'post',
    data: data
  })
}

// 删除用户登录记录
export function delUser(data) {
  return request({
    url: '/biz/userLogin/delete',
    method: 'post',
    data: data
  })
}

// 封禁IP
export function banUserIp(data) {
  return request({
    url: '/biz/userLogin/banUserIp',
    method: 'post',
    data: data
  })
}
export function banDeviceNo(data) {
  return request({
    url: '/biz/userLogin/banDeviceNo',
    method: 'post',
    data: data
  })
}


